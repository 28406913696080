// brianreyman_styles.sass


////
// import some other sheets
////
// variable/standard setting configuration
@import "config";

@import "custom-sass-library/css-spacing";

@import "bulma";

// pushes the footer to the bottom of each page
body.default {
  display: flex;
  // use this if navbar is NOT fixed (default)
  min-height: 100vh;
  flex-direction: column; }

body.fixed {
  display: flex;
  // use this if navbar is fixed
  min-height: calc(100vh - #{$navbar-height});
  flex-direction: column; }

.main {
  flex: 1; }

// provides full page centering
.is-full-height-vcentered {
  display: flex;
  height: 80vh; }

// horizontally center items
.is-horizontal-centered {
  justify-content: center; }

// changes color when rolled
.icon.has-text-primary.is-hovered:hover {
  color: $primary-hover !important; }

// custom image handling to properly size images
.is-128wide {
  width: 128px !important; }

.is-150wide {
  width: 150px !important; }

.is-200wide {
  width: 200px !important; }

// navigation bar updates
// hide icons and logo when in the burger
@media screen and (max-width: $desktop - 1px) {
  .navbar-icon, .navbar-logo {
    display: none !important; } }

// handle bullets
ul li {
  list-style-type: square;
  margin-left: 25px; }

// center nav bar navbar items
.navbar-move {
  flex-grow: 1; }

.navbar-move-left {
  @extend .navbar-move;
  justify-content: flex-start; }

.navbar-move-right {
  @extend .navbar-move;
  justify-content: flex-end; }

// tab font size tweaking
.tabs li {
  font-size: .9em !important;
  margin-left: 1em !important; }


// custom bit of handling for bulma tabs on mobile - works with non-icon situations
@media screen and (max-width: $desktop - 1px) {
  .tabs {
    ul {
      display: block;
      text-align: left;
      flex-grow: 1;
      li {
        padding-left: 0px !important;
        margin-left: 0px !important;
        a {
          justify-content: left;
          border: 1px solid $grey-lighter !important;
          padding-left: 35px; }
        &.is-active a {
          border-bottom: 1px solid $grey-lighter !important;
          padding-left: 15px; } } } } }


// image styling
figure.is-portfolio-index {
  border-style: solid;
  border-width: 1px;
  border-color: #cccccb; }

.my-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .3s ease;
  background-color: #333; }

.my-container:hover .my-overlay {
  opacity: 1; }

.my-text {
  color: white;
  font-size: 40px;
  font-weight: 600;
  line-height: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%); }

.is-cropped-square {
  object-fit: cover; }

.has-hand-pointer {
  cursor: pointer; }

// padding for headings
.has-no-bottom-margins {
  margin-bottom: 5px !important;
  padding-bottom: 0px !important; }


// set of items for the blog page. Inheriting lots of stuff directly.
.is-blog h2 {
  @extend .subtitle;
  @extend .has-top-margin-30;
  margin-bottom: 5px !important; }

.is-blog h3 {
  @extend .subtitle;
  @extend .is-4;
  margin-bottom: 5px !important; }

.is-blog {
  font-size: 1.05em; }

.is-blog p {
  @extend .has-bottom-margin-20; }

.is-blog .footnotes {
  font-size: .75em;
  border-top: .5px solid #999;
  width: 80%;
  @extend .has-top-margin-50;
  @extend .has-bottom-margin-50; }

.is-blog p.subtitle {
  margin-bottom: 5px !important; }

.is-blog p.subtitle a:link, .is-blog p.subtitle a:visited {
  color: $primary; }

ul.blog-list li {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.is-blog .image-label-non-ending {
  @extend .has-top-margin-0;
  @extend .has-left-margin-20;
  @extend .has-right-margin-20; }

.is-blog .image-label {
  @extend .has-top-margin-0;
  @extend .has-left-margin-20;
  @extend .has-right-margin-20;
  @extend .has-bottom-margin-50; }

.is-blog .small-margins {
  @extend .has-top-margin-0;
  @extend .has-bottom-margin-0;
  @extend .has-left-margin-0;
  @extend .has-bottom-margin-0; }
