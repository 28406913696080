
// _config.scss

$color-primary : #000000;
$color-secondary : #999;

$brown : #7D4503;
$light-brown : #C0A187;
$teal : #39B8BD;
$light-teal : #7ECECE;
$light-blue : #9cc7e7;
$youtube-red : #c4302b;

$primary : $color-primary;
$primary-hover : $light-teal;

$navbar-height : 5rem;
$navbar-item-img-max-height : 6em;

// update font settings
$body-font-size : 1em;
$body-line-height : 1.7;

$navbar-dropdown-arrow : $color-secondary;
$navbar-dropdown-item-active-color : $color-secondary;
$navbar-dropdown-item-hover-color : $color-secondary;

// update tab settings
$tabs-link-active-color: $color-primary;
$tabs-toggle-link-active-background-color: $color-primary;
